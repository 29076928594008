import React from "react";
import P from "prop-types";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import { StaticImage } from "gatsby-plugin-image";

const svgString = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 139.81"><path d="M1919.62,699.72C1788,735,1456,806,964.89,699.72c-314.23-68-646.64-43-964.51,0L0,616H1920Z" transform="translate(0 -616)" style="fill:#fff"/></svg>`;

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title="Systemutvecklare och konsulter i Malmö/Lund" />
      <Helmet>
        <meta
          name="description"
          content="Innomentas konsulter kan modern effektiv mjukvarutveckling. Vi jobbar tätt med ert team
          för att få er mjukvara att nå målen på bästa sätt."
        />
      </Helmet>

      <section className="hero">
        <div className="container">
          <div className="row justify-content-between pt-12 pb-12">
            <div className="col-lg-6">
              <h1 className="heading-yellow">
                Innomenta - konsulter i Malmö/Lund
              </h1>
              <p className="subtitle">
                Innomenta har konsulter med lång erfarenhet från både stora och
                små bolag. Vi jobbar tätt med er för att få er mjukvara att nå
                målen, oavsett om vi jobbar i ert team eller utvecklar mjukvara
                åt er hos oss.
              </p>
              <Link className="button button-primary" to="/kontakta-oss">
                Kontakta oss
              </Link>
            </div>
            <div className="col-lg-5 d-none d-lg-flex justify-content-center align-items-center">
              <StaticImage alt="Hero" src="../images/hero.png" />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
          }}
          dangerouslySetInnerHTML={{ __html: svgString }}
        />
      </section>
      <article className="solutions pt-20 pb-10">
        <div className="container">
          <div className="row justify-content-center pb-6">
            <h2 className="">Tjänster</h2>
          </div>
          <div className="row">
            {markdown.map((edge) => (
              <div
                key={edge.node.frontmatter.path}
                className="col-md-6 col-lg-4"
              >
                <Link className="card-wrapper" to={edge.node.frontmatter.path}>
                  <div className="card pt-4">
                    <h3 className="text-dark">{edge.node.frontmatter.title}</h3>
                    <p>{edge.node.excerpt}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="row justify-content-center mt-6">
            <Link className="button button-secondary" to="/tjänster">
              Våra tjänster
            </Link>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/tjänster/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
            author {
              name
              avatar
              linkedin
              key
              jobtitle
            }
          }
          excerpt
        }
      }
    }
  }
`;

Home.propTypes = {
  data: P.shape({
    allMarkdownRemark: P.shape({}).isRequired,
  }).isRequired,
};

export default Home;
